import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { MultipleSelect } from "ui-atoms";
import debounce from "lodash.debounce";

interface ISearchableMultipleSelect {
  label?: string;
  name?: string;
  className?: string;
  onChange?: any;
  title?: string;
  serviceAPI?: any;
  align?: string;
  options?: any;
  isSearch?: boolean;
  isMissing?: boolean;
  isLabelValue?: boolean;
  zIdx?: string;
  divClassName?: string;
  filters?: any;
  setFilters?: any;
}

const SearchableMultipleSelect = ({
  label = "",
  name = "",
  className,
  onChange,
  title = "",
  serviceAPI,
  align = "left",
  options,
  isSearch = true,
  isMissing = true,
  isLabelValue,
  zIdx,
  divClassName,
  filters,
  setFilters,
}: ISearchableMultipleSelect) => {
  const location = useLocation();
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [loadedOptions, setLoadedOptions] = useState<any>([]);
  const [searchWord, setSearchWord] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (filters) {
      setParamFilters(filters);
    } else {
      const newFilters = queryString.parse(location?.search, {
        arrayFormat: "bracket",
      });
      setParamFilters(newFilters);
    }
  }, [location, filters]);

  useEffect(() => {
    if (paramFilters && loadedOptions) {
      setSelectedOptions(
        loadedOptions?.filter((option: any) => {
          if (option?.value?.toString()?.includes("_missing")) {
            return !!paramFilters[option?.value];
          }
          return (
            paramFilters?.[name]?.includes(option?.value?.toString()) ||
            paramFilters?.[name]?.includes(option?.value)
          );
        })
      );
    }
  }, [paramFilters, loadedOptions]);

  useEffect(() => {
    if (paramFilters && serviceAPI) {
      setOptions(searchWord);
    } else if (paramFilters && options) {
      setLoadedOptions(options);
    }
  }, [paramFilters, options]);

  const setOptions = async (keyword: string = "") => {
    if (serviceAPI) {
      let payload: any = {};
      if (paramFilters?.[name] && name === "market") {
        payload = { ...payload, markets: paramFilters?.[name] };
      } else if (paramFilters?.[name] && name === "broker") {
        payload = {
          ...payload,
          id: paramFilters?.[name],
          limit: paramFilters?.[name]?.length,
        };
      } else if (paramFilters?.[name]) {
        payload = { ...payload, [name]: paramFilters?.[name] };
      }

      let original_result = null;
      if (!!Object.keys(payload)?.length)
        original_result = await serviceAPI(payload);
      const keyword_result = await serviceAPI({ keyword });

      let original_options: any = [];
      if (original_result?.docs)
        original_options = original_result?.docs?.filter((result: any) => {
          let updated_name = name;
          if (name === "market") updated_name = "markets";
          if (paramFilters?.[updated_name]?.includes(result?.pk?.toString()))
            return true;
        });
      let keyword_options: any = [];
      if (keyword_result?.docs)
        keyword_options = keyword_result?.docs?.filter(
          (result: any) =>
            !original_options?.length ||
            !original_options?.find(
              (option: any) => option?.pk?.toString() === result?.pk?.toString()
            )
        );
      let options = [...original_options, ...keyword_options];
      let missingArr: any = [];
      if (isMissing)
        missingArr = [
          {
            label: "Missing",
            value: `${name}_missing`,
            id: `${name}_missing`,
          },
        ];
      setLoadedOptions([
        ...missingArr,
        ...options?.map((user: any) => {
          return {
            value: isLabelValue ? user?.name : user?.pk,
            label:
              user?.first_name || user?.last_name
                ? [user?.first_name, user?.last_name].join(" ")
                : user?.name
                ? user?.name
                : user?.email,
            id: `${name}_${user?.pk}`,
          };
        }),
      ]);
    } else {
      const filteredOptions = options?.filter(
        (option: any) =>
          option?.label?.toLowerCase()?.includes(keyword?.toLowerCase()) ||
          option?.value
            ?.toString()
            .toLowerCase()
            ?.includes(keyword?.toLowerCase())
      );
      setLoadedOptions(filteredOptions);
    }
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(setOptions, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    if (!!e?.isDefaultPrevented) e?.preventDefault();
    setSearchWord(e?.target?.value);
    debounceUpdateKeyword(e?.target?.value);
  };

  return (
    <MultipleSelect
      options={loadedOptions}
      selectedOptions={selectedOptions}
      onChangeInput={isSearch && onChangeInput}
      searchWord={searchWord}
      name={name}
      title={title}
      label={label}
      className={className}
      onChange={onChange}
      align={align}
      zIdx={zIdx}
      divClassName={divClassName}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

export default SearchableMultipleSelect;
