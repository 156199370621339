import { AuthHome } from "ui-organisms";
import cn from "classnames";
import queryString from "query-string";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  EmptyResult,
  Input,
  Loading,
  MultipleSelect,
  SearchableMultipleSelect,
  Toast,
} from "ui-atoms";
import {
  FiEdit3,
  FiGrid,
  FiPlusCircle,
  FiRotateCcw,
  FiSearch,
} from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useApiCall } from "hooks";
import {
  deleteTaskAPI,
  getAvailabilitiesAPI,
  getBrokersAPI,
  getMarketsAPI,
  getPropertiesAPI,
  getTasksAPI,
  getUsersAPI,
  patchPropertyAPI,
  patchTaskAPI,
  postTableSchemaAPI,
} from "services";
import {
  PropertyProps,
  AvailabilityProps,
  DirectionEnum,
  TaskProps,
  CoordinatorProps,
  BrokerProps,
  FilteredFacetProps,
  FacetsProps,
} from "types";
import {
  ALL_AVAILABILITIES_COLUMNS,
  ALL_PROPERTY_COLUMNS,
  AUTH_HOME_TABS,
  AUTH_TABS,
  AVAILABILITIES_TABLE_COLUMNS,
  CONTRACT_TABLE_COLUMNS,
  EVENT_REMOVED_INACTIVE_PROPERTY,
  EventProps,
  META_TAGS,
  PROPERTIES_TABLE_COLUMNS,
  SET_CONFIRM_MODAL,
  SET_EVENT_MODAL,
  SET_INLINE_EDIT_SELECT_MODAL,
  SET_LOADING_MODAL,
  SET_META,
  SET_PROPERTY_REMOVE_CONFIRM_MODAL,
  STATUS_ACTIVE,
  STATUS_REMOVED,
  TASK_TABLE_COLUMNS,
  URLS,
} from "constant";
import {
  Facets,
  RemoveDescriptionItem,
  StyledAvailabilityValue,
  StyledContractValue,
  StyledPropertyValue,
  StyledTaskValue,
  Table,
  TaskEditModal,
  TaskPreviewModal,
} from "ui-molecules";
import { SEARCH_RESULT_LIMIT } from "./constants";
import debounce from "lodash.debounce";
import MyActivity from "./MyActivity";
import { Helmet } from "react-helmet-async";
import PropertyModal from "pages/Properties/PropertyModal";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { GlobalContext } from "context";
import { renderToString } from "react-dom/server";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  getContactTypeId,
  getFilteredFacets,
  getStateOptions,
  reorder,
} from "utils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import React from "react";

const AuthHomepage = () => {
  const { dispatch, state } = useContext(GlobalContext);
  const { meta, portalRef } = state;
  const [getProperties, isLoadingProperties] = useApiCall(getPropertiesAPI);
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [getAvailabilities, isLoadingAvailabilities] =
    useApiCall(getAvailabilitiesAPI);
  const [getMarkets] = useApiCall(getMarketsAPI);
  const [fetchTask, isLoadingTasks] = useApiCall(getTasksAPI);
  const [deleteTask] = useApiCall(deleteTaskAPI);
  const [patchTask] = useApiCall(patchTaskAPI);
  const [getUsers] = useApiCall(getUsersAPI);
  const [getBrokers] = useApiCall(getBrokersAPI);
  const [postTableSchema] = useApiCall(postTableSchemaAPI);
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<any>(AUTH_TABS.PROPERTY);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [filterFacets, setFilterFacets] = useState<FilteredFacetProps[]>([]);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any>();
  const [keyword, setKeyword] = useState<string>();
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [oldParam, setOldParam] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isActivity, setIsActivity] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isInlineEditMode, setIsInlineEditMode] = useState(false);
  const [columns, setColumns] = useState<any>([]);

  // let columns: any[] = [];
  let service: (arg0: any) => Promise<any>;
  if (activeTab === AUTH_TABS.PROPERTY) {
    service = getProperties;
  } else if (activeTab === AUTH_TABS.AVAILABILITY) {
    service = getAvailabilities;
  } else if (activeTab === AUTH_TABS.TASK) {
    service = fetchTask;
  } else if (activeTab === AUTH_TABS.CONTRACT) {
    service = getProperties;
  }

  const paramWithUserId = (paramFilters: any) => {
    if (
      activeTab === AUTH_TABS.PROPERTY ||
      activeTab === AUTH_TABS.AVAILABILITY ||
      activeTab === AUTH_TABS.CONTRACT
    )
      return {
        ...paramFilters,
        coordinator: session?.id ? [session?.id] : [],
        broker: paramFilters?.broker
          ? [
              ...paramFilters?.broker,
              ...(session?.broker_id ? [session?.broker_id] : []),
            ]
          : [...(session?.broker_id ? [session?.broker_id] : [])],
        broker_or_coordinator: true,
      };
    else if (activeTab === AUTH_TABS.TASK)
      return {
        ...paramFilters,
        assignee: [session?.id],
      };
  };

  useEffect(() => {
    const filters: any = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    if (filters?.tab) {
      setActiveTab(filters?.tab?.toString()?.toLowerCase());
    }
    setKeyword(filters?.keyword?.toString() || "");
    setOldParam(filters);
    if (filters?.activity_keyword !== oldParam?.activity_keyword) return;
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (paramFilters === null) return;
    setKeyword(paramFilters?.keyword || "");
    setPage(1);
    if (!service) return;
    if (!session) return;
    setData([]);
    service({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      ...paramWithUserId(paramFilters),
    }).then((res: any) => {
      setData(res?.docs);
      setPage(res?.page);
      setTotal(res?.total);
      setFilterFacets(getFilteredFacets(res?.facets as FacetsProps));
      setSortFields(res?.sort_fields);
    });
  }, [paramFilters, activeTab]);

  useEffect(() => {
    setIsInlineEditMode(false);
  }, [activeTab]);

  useEffect(() => {
    if (!meta) return;
    if (activeTab === AUTH_TABS.PROPERTY) {
      const content_type = getContactTypeId(meta, "building")?.[0];
      setColumns(
        !!meta?.table_schema?.length &&
          !!meta?.table_schema?.find(
            (schema: any) => schema?.content_type === content_type
          )
          ? JSON.parse(
              meta?.table_schema?.find(
                (schema: any) => schema?.content_type === content_type
              )?.json
            )
          : PROPERTIES_TABLE_COLUMNS
      );
    } else if (activeTab === AUTH_TABS.AVAILABILITY) {
      const content_type = getContactTypeId(meta, "suite")?.[0];
      setColumns(
        !!meta?.table_schema?.length &&
          !!meta?.table_schema?.find(
            (schema: any) => schema?.content_type === content_type
          )
          ? JSON.parse(
              meta?.table_schema?.find(
                (schema: any) => schema?.content_type === content_type
              )?.json
            )
          : AVAILABILITIES_TABLE_COLUMNS
      );
    } else if (activeTab === AUTH_TABS.TASK) {
      setColumns(TASK_TABLE_COLUMNS);
    } else if (activeTab === AUTH_TABS.CONTRACT) {
      setColumns(CONTRACT_TABLE_COLUMNS);
    }
  }, [activeTab, meta]);

  const handleTab = (value: string) => () => {
    const updatedFilters: any = { tab: value };
    if (value === AUTH_TABS.AVAILABILITY)
      updatedFilters["active_status"] = [STATUS_ACTIVE];
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const loadMore = () => {
    if (!service) return;
    const nextPage = page + 1;
    setPage(nextPage);
    service({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      ...paramWithUserId(paramFilters),
    }).then((res: any) => {
      setData((prevData: any) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
      setFilterFacets(getFilteredFacets(res?.facets as FacetsProps));
      setSortFields(res?.sort_fields);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const updateKeywordFilter = (e: any) => {
    const filters = queryString.parse(location?.search);
    const updatedFilters = { ...filters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters?.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const onClickReset = () => {
    const filters = { ...queryString.parse(location?.search) };
    let newFilter: any = {};
    if (filters?.hasOwnProperty("tab")) {
      newFilter["tab"] = filters?.tab;
    }
    if (filters?.hasOwnProperty("activity_keyword")) {
      newFilter["activity_keyword"] = filters?.activity_keyword;
    }
    navigate(`${location.pathname}?${queryString.stringify(newFilter)}`);
  };

  const onClickRow = (item: any) => () => {
    if (activeTab === AUTH_TABS.PROPERTY) {
      navigate(`/property/${item?.pk}/${URLS.PROPERTY.FORM}`);
    } else if (activeTab === AUTH_TABS.AVAILABILITY) {
      navigate(
        `/property/${item?.building}/availability/${item?.pk}/${URLS.AVAILABILITY.FORM}`
      );
    } else if (activeTab === AUTH_TABS.TASK) {
      setSelectedTask(item);
      setIsPreviewOpen(true);
    } else if (activeTab === AUTH_TABS.CONTRACT) {
      navigate(`/property/${item?.pk}/${URLS.PROPERTY.ADMIN}`);
    }
  };

  const onClickMarketSphere = () => {
    setIsOpen(true);
  };

  const openEditModal = () => {
    setIsPreviewOpen(false);
    setIsEditOpen(true);
  };

  const onClickTaskEdit = (e: any, task: TaskProps) => {
    e.stopPropagation();
    setSelectedTask(task);
    setIsEditOpen(true);
  };

  const onClickTaskRemove = (e: any, task: TaskProps) => {
    e.stopPropagation();
    const action = () => {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Deleting the task`,
        },
      });
      deleteTask(task?.pk)
        .then((res: any) => {
          Toast.success("Task removed with success!");
          const index = data?.findIndex(
            (item: TaskProps) => item?.pk === task?.pk
          );
          if (index !== undefined && index > -1) {
            const newData = [...data];
            newData[index]["active_status"] = STATUS_REMOVED;
            setData(newData);
          }
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    };

    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: {
        open: true,
        content: {
          description: renderToString(
            <RemoveDescriptionItem target={task?.title} />
          ),
          action,
        },
      },
    });
  };

  const onClickPropertyRemove = (e: any, item: PropertyProps) => {
    e.stopPropagation();
    const action = (event: EventProps) => {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Removing the property from My Listings`,
        },
      });

      const broker_ids =
        (item?.broker as BrokerProps[])
          ?.filter((value: BrokerProps) => value.id !== session?.broker_id)
          ?.map((contact) => contact?.id) || [];
      const coordinator_ids =
        (item?.coordinator || item?.coordinators)
          ?.filter((value: CoordinatorProps) => value.id !== session?.id)
          ?.map((coordinator: CoordinatorProps) => coordinator?.id) || [];

      const body = {
        id: item?.pk,
        payload: {
          broker: broker_ids,
          coordinator: coordinator_ids,
          ...{ ...event },
        },
      };

      patchProperty(body)
        .then((res: any) => {
          if (!res?.id) return;
          Toast.success("Property removed with success!");
          const newData = [...(data || [])]?.filter(
            (value: PropertyProps) => value?.pk !== res?.id
          );
          setTotal(newData?.length);
          setData(newData);
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        });
    };

    const handleEventModal = () => {
      dispatch({
        type: SET_EVENT_MODAL,
        payload: {
          open: true,
          content: {
            title: "Remove Property",
            label: `What is reason for removing this property?`,
            eventType: EVENT_REMOVED_INACTIVE_PROPERTY,
            buttonLabel: "Remove Property",
            action,
          },
        },
      });
    };
    if (item?.publish_status === STATUS_ACTIVE) {
      dispatch({
        type: SET_PROPERTY_REMOVE_CONFIRM_MODAL,
        payload: {
          open: true,
          content: {
            continueAction: handleEventModal,
          },
        },
      });
    } else {
      handleEventModal();
    }
  };

  const onActiveUpdate = async (id: any, active_status: number) => {
    const response = await patchTask({
      id,
      payload: {
        active_status,
      },
    });
    if (!response) return;
    Toast.success("Updated successfully");
    if (activeTab === AUTH_TABS.TASK) {
      const index = data?.findIndex(
        (item: TaskProps) => item?.pk === response?.id
      );
      if (index !== undefined && index > -1) {
        const newData = [...data];
        newData[index]["active_status"] = response?.active_status;
        setData(newData);
      }
    }
  };

  const onClickTaskReinstate = async (e: any, task: TaskProps) => {
    e.stopPropagation();
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          label: `Reinstating the task`,
        },
      });
      await onActiveUpdate(task?.pk, STATUS_ACTIVE);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            label: null,
          },
        });
      }, 100);
    }
  };

  const updateData = (res: TaskProps) => {
    const index = data?.findIndex(
      (item: TaskProps) => item?.pk === res?.pk || item?.pk === res?.id
    );
    if (index !== undefined && index > -1) {
      // Edit
      const newData = [...data];
      newData[index] = {
        ...newData[index],
        ...res,
      };
      setData(newData);
    } else {
      // Add
      let newData = [...data];
      newData.push({
        ...res,
        pk: res?.id,
      });
      setData(newData);
    }
  };

  const updateItem = (newValues: any, pk: string | number) => {
    const index = data?.findIndex(
      (item: PropertyProps | AvailabilityProps) =>
        item?.pk === pk || item?.id === pk
    );
    if (index !== undefined && index > -1) {
      const newData = [...data];
      newData[index] = {
        ...newData[index],
        ...newValues,
      };
      setData(newData);
    }
  };

  const handleColumnSubmit = (data: any) => {
    try {
      const content_type =
        activeTab === AUTH_TABS.PROPERTY
          ? getContactTypeId(meta, "building")?.[0]
          : getContactTypeId(meta, "suite")?.[0];
      const payload = {
        content_type,
        json: JSON.stringify(data),
      };
      postTableSchema(payload).then(() => {
        let table_schema = meta?.table_schema;
        const index = table_schema?.findIndex(
          (schema: any) => schema?.content_type === content_type
        );
        if (!table_schema) table_schema = [];
        if (index > -1) table_schema[index] = payload;
        else table_schema.push(payload);
        dispatch({
          type: SET_META,
          payload: {
            ...meta,
            table_schema,
          },
        });
      });
    } catch (err) {}
  };

  const handleColumn = () => {
    let totalColumns;
    if (activeTab === AUTH_TABS.PROPERTY) {
      totalColumns = ALL_PROPERTY_COLUMNS;
    } else if (activeTab === AUTH_TABS.AVAILABILITY) {
      totalColumns = ALL_AVAILABILITIES_COLUMNS;
    }
    dispatch({
      type: SET_INLINE_EDIT_SELECT_MODAL,
      payload: {
        open: true,
        content: {
          line: 2,
          list: totalColumns,
          label: "Add Column",
          current: columns,
          func: handleColumnSubmit,
        },
      },
    });
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newOrder = reorder(
      [...columns],
      result.source.index,
      result.destination.index
    );
    setColumns(newOrder);
    handleColumnSubmit(newOrder);
  };

  return (
    <AuthHome>
      <Helmet prioritizeSeoTags>
        <title>{META_TAGS?.home?.title}</title>
        <meta property="og:title" content={META_TAGS?.home?.title} />
      </Helmet>
      <div className="fixed top-[64px] right-0 bottom-0 left-0 bg-white shadow-detail-status-nav px-0">
        <AuthHome.Navbar className="border-b border-jll-color-coldGray-3 px-8">
          <div className="flex flex-row space-x-8 h-full">
            {AUTH_HOME_TABS?.map((tab: any, idx: number) => (
              <div
                className={cn(
                  "cursor-pointer font-semibold text-base flex items-center",
                  {
                    "text-jll-color-text-base-default border-b border-jll-color-coldGray-7":
                      activeTab?.toLowerCase() === tab.value,
                    "text-jll-color-coldGray-5":
                      activeTab?.toLowerCase() !== tab.value,
                  }
                )}
                key={idx}
                onClick={handleTab(tab?.value)}
              >
                {tab?.label}
              </div>
            ))}
          </div>
          <div className="flex space-x-4 items-center">
            {(activeTab === AUTH_TABS.PROPERTY ||
              activeTab === AUTH_TABS.AVAILABILITY) && (
              <div className="flex flex-row items-center space-x-3">
                {activeTab === AUTH_TABS.PROPERTY && (
                  <>
                    {(() => {
                      if (!meta) return;
                      const options = getStateOptions(meta);
                      if (!options) return;
                      return (
                        <SearchableMultipleSelect
                          name="state"
                          title="State"
                          options={options}
                        />
                      );
                    })()}
                    {(() => {
                      const data = filterFacets?.find(
                        (facet) => facet?.id === "city"
                      );
                      if (!data) return;
                      return (
                        <SearchableMultipleSelect
                          name="city"
                          title="City"
                          options={data?.facets}
                        />
                      );
                    })()}
                    {(() => {
                      const data = filterFacets?.find(
                        (facet) => facet?.id === "market"
                      );
                      if (!data) return;
                      return (
                        <SearchableMultipleSelect
                          name={data?.id}
                          title={data?.label}
                          serviceAPI={getMarkets}
                          isMissing={false}
                          isLabelValue
                        />
                      );
                    })()}
                  </>
                )}
                {(() => {
                  const data = filterFacets?.find(
                    (facet) => facet?.id === "coordinator"
                  );
                  if (!data) return;
                  return (
                    <SearchableMultipleSelect
                      name={data?.id}
                      title={data?.label}
                      serviceAPI={getUsers}
                    />
                  );
                })()}
                {(() => {
                  const data = filterFacets?.find(
                    (facet) => facet?.id === "broker"
                  );
                  if (!data) return;
                  return (
                    <SearchableMultipleSelect
                      name={data?.id}
                      title={data?.label}
                      options={data?.facets}
                    />
                  );
                })()}
              </div>
            )}
            <Input
              className="w-[400px] !mb-0"
              name="search"
              leadingIcon={FiSearch}
              onChange={onChangeInput}
              value={keyword}
              isClearable
              placeholder={`Search ${
                activeTab === AUTH_TABS.PROPERTY
                  ? "my properties"
                  : activeTab === AUTH_TABS.AVAILABILITY
                  ? "my availabilities"
                  : "my tasks"
              }`}
            />
            {activeTab === AUTH_TABS.PROPERTY && (
              <Button
                variant="primary"
                leadingIcon={FiPlusCircle}
                onClick={onClickMarketSphere}
              >
                Add Property
              </Button>
            )}
            {activeTab === AUTH_TABS.TASK && (
              <Button
                variant="primary"
                leadingIcon={FiPlusCircle}
                onClick={() => {
                  setSelectedTask(null);
                  setIsEditOpen(true);
                }}
              >
                Add task
              </Button>
            )}
          </div>
        </AuthHome.Navbar>
        <div className="absolute w-full top-[80px] grid grid-cols-12 overflow-hidden bottom-0">
          {activeTab !== AUTH_TABS.CONTRACT && (
            <Facets filterFacets={filterFacets} />
          )}
          <div
            className={cn(
              "absolute top-0 right-0 bottom-0 col-span-10 h-full ",
              {
                "left-[300px]": activeTab !== AUTH_TABS.CONTRACT,
                "left-[0px]": activeTab === AUTH_TABS.CONTRACT,
              }
            )}
          >
            <div className="flex space-x-4 h-[70px] items-center px-6 justify-between">
              <div className="font-semibold text-jll-color-coldGray-7 text-sm mr-4">
                {total} result{total > 1 ? "s" : ""}
              </div>

              <div className="flex flex-row items-center space-x-2">
                {activeTab === AUTH_TABS.CONTRACT &&
                  filterFacets?.map((facetCategory, facet_index) => {
                    if (!facetCategory.facets?.length) {
                      return null;
                    }
                    let align = "left";
                    if (
                      filterFacets?.length > 2 &&
                      facet_index > filterFacets?.length - 2
                    )
                      align = "right";
                    if (facetCategory?.id === "coordinator") {
                      return (
                        <SearchableMultipleSelect
                          key={facet_index}
                          name={facetCategory?.id}
                          title={facetCategory?.label}
                          serviceAPI={getUsers}
                          align={align}
                        />
                      );
                    }

                    if (facetCategory?.id === "broker") {
                      return (
                        <SearchableMultipleSelect
                          key={facet_index}
                          name={facetCategory?.id}
                          title={facetCategory?.label}
                          serviceAPI={getBrokers}
                          align={align}
                        />
                      );
                    }

                    return (
                      <MultipleSelect
                        key={facet_index}
                        options={facetCategory?.facets}
                        selectedOptions={facetCategory?.facets?.filter(
                          (facet) => {
                            if (
                              facet?.value?.toString()?.includes("_missing")
                            ) {
                              return !!paramFilters[facet?.value];
                            }
                            return (
                              paramFilters?.[facetCategory?.id]?.includes(
                                facet?.value?.toString()
                              ) ||
                              paramFilters?.[facetCategory?.id]?.includes(
                                facet?.value
                              )
                            );
                          }
                        )}
                        name={facetCategory?.id}
                        title={facetCategory?.label}
                        align={align}
                      />
                    );
                  })}
                {(activeTab === AUTH_TABS.PROPERTY ||
                  activeTab === AUTH_TABS.AVAILABILITY) &&
                  (!isInlineEditMode ? (
                    <Button
                      variant="secondary"
                      leadingIcon={FiEdit3}
                      onClick={() => setIsInlineEditMode(true)}
                    >
                      Edit mode
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      leadingIcon={FiGrid}
                      onClick={() => setIsInlineEditMode(false)}
                    >
                      View mode
                    </Button>
                  ))}
                {paramFilters &&
                  !!Object.keys(paramFilters)?.filter(
                    (key) => key !== "tab" && key !== "activity_keyword"
                  )?.length && (
                    <Button
                      variant="ghost"
                      size="small"
                      leadingIcon={FiRotateCcw}
                      onClick={onClickReset}
                    >
                      Reset
                    </Button>
                  )}
              </div>
            </div>
            {(!!isLoadingAvailabilities ||
              !!isLoadingProperties ||
              !!isLoadingTasks) &&
              !data?.length && <Loading />}
            {!isLoadingAvailabilities &&
              !isLoadingProperties &&
              !isLoadingTasks &&
              !data?.length && (
                <EmptyResult>
                  {activeTab === AUTH_TABS.AVAILABILITY && (
                    <>
                      <h1>
                        {session?.get_full_name
                          ? `Welcome, ${session?.get_full_name}`
                          : ""}
                      </h1>
                      <p className="mt-4 text-body1 text-jll-color-coldGray-7 mb-3 text-center">
                        You don’t have any availability listings. You can browse
                        existing availabilities{" "}
                        <Link
                          className="text-jll-color-icon-info"
                          to={"/availabilities?active_status[]=1"}
                        >
                          here
                        </Link>{" "}
                        or add properties by searching MarketSphere below:
                      </p>
                      <div className="flex space-x-4">
                        <Link to="/properties">
                          <Button variant="primary" leadingIcon={FiSearch}>
                            Browse Listings
                          </Button>
                        </Link>
                        <Button
                          variant="primary"
                          leadingIcon={FiPlusCircle}
                          onClick={onClickMarketSphere}
                        >
                          Add Property
                        </Button>
                      </div>
                    </>
                  )}
                  {activeTab === AUTH_TABS.PROPERTY && (
                    <>
                      <h1>
                        {session?.get_full_name
                          ? `Welcome, ${session?.get_full_name}`
                          : ""}
                      </h1>
                      <p className="mt-4 text-body1 text-jll-color-coldGray-7 mb-3 text-center">
                        You don’t have any property listings. To get started,
                        please click the add property to add a Property to My
                        Listings.
                      </p>
                      <Button
                        variant="primary"
                        leadingIcon={FiPlusCircle}
                        onClick={onClickMarketSphere}
                      >
                        Add Property
                      </Button>
                    </>
                  )}
                  {activeTab === AUTH_TABS.TASK && (
                    <h3 className="text-heading3 text-jll-color-coldGray-5">
                      There are no active tasks
                    </h3>
                  )}
                </EmptyResult>
              )}
            {!!data?.length && (
              <div className="w-full h-[calc(100%-70px)] px-6 pb-6 overflow-auto">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => {
                      return (
                        <Table
                          isEdit={isInlineEditMode}
                          handleColumn={handleColumn}
                        >
                          <div className="hidden">{provided?.placeholder}</div>
                          <Table.Thead>
                            <Table.Tr
                              className="z-[3]"
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {columns?.map((column: any, index: number) => {
                                return (
                                  <Draggable
                                    key={column.id}
                                    draggableId={`${column?.id}`}
                                    index={index}
                                    isDragDisabled={!isInlineEditMode}
                                  >
                                    {(provided, snapshot) => {
                                      const usePortal: boolean =
                                        snapshot.isDragging;
                                      const child = (
                                        <Table.Th
                                          filterId={column.id}
                                          width={column?.width || null}
                                          sortFields={sortFields}
                                          filters={paramFilters}
                                          onClick={onClickSorting}
                                          isEdit={isInlineEditMode}
                                          editable={true}
                                          ref={provided.innerRef}
                                          draggableProps={
                                            provided?.draggableProps
                                          }
                                          dragHandleProps={
                                            provided?.dragHandleProps
                                          }
                                          className={cn(column?.th?.className)}
                                          itemClassName={cn({
                                            "bg-jll-color-coldGray-1 ":
                                              snapshot.isDragging,
                                          })}
                                          handleColumnSubmit={
                                            handleColumnSubmit
                                          }
                                          currentColumns={[...columns]}
                                          handleColumn={handleColumn}
                                        >
                                          {column.label}
                                        </Table.Th>
                                      );
                                      if (!usePortal) {
                                        return child;
                                      }
                                      return ReactDOM.createPortal(
                                        child,
                                        portalRef?.current
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {activeTab === AUTH_TABS.TASK ||
                                (activeTab === AUTH_TABS.PROPERTY && (
                                  <Table.Th isEdit={isInlineEditMode} />
                                ))}
                            </Table.Tr>
                          </Table.Thead>
                          <Table.Tbody>
                            {!!data?.length &&
                              data?.map(
                                (
                                  item:
                                    | AvailabilityProps
                                    | PropertyProps
                                    | TaskProps,
                                  index: number
                                ) => {
                                  return (
                                    <Table.Tr
                                      key={index}
                                      onClick={onClickRow(item)}
                                      isEdit={isInlineEditMode}
                                    >
                                      {columns.map(
                                        (column: any, index: number) => {
                                          return (
                                            <Table.Td
                                              key={index}
                                              className={column?.td?.className}
                                              isEdit={isInlineEditMode}
                                            >
                                              {activeTab ===
                                                AUTH_TABS.AVAILABILITY && (
                                                <StyledAvailabilityValue
                                                  availability={
                                                    item as AvailabilityProps
                                                  }
                                                  valueKey={column.id}
                                                  isEditMode={isInlineEditMode}
                                                  updateItem={updateItem}
                                                />
                                              )}
                                              {activeTab ===
                                                AUTH_TABS.PROPERTY && (
                                                <StyledPropertyValue
                                                  property={
                                                    item as PropertyProps
                                                  }
                                                  valueKey={column.id}
                                                  isEditMode={isInlineEditMode}
                                                  updateItem={updateItem}
                                                />
                                              )}
                                              {activeTab === AUTH_TABS.TASK && (
                                                <StyledTaskValue
                                                  task={item as TaskProps}
                                                  valueKey={column.id}
                                                />
                                              )}
                                              {activeTab ===
                                                AUTH_TABS.CONTRACT && (
                                                <StyledContractValue
                                                  property={
                                                    item as PropertyProps
                                                  }
                                                  valueKey={column.id}
                                                  dataType={column?.type}
                                                  updateItem={updateItem}
                                                />
                                              )}
                                            </Table.Td>
                                          );
                                        }
                                      )}
                                      {activeTab === AUTH_TABS.PROPERTY && (
                                        <Table.Td
                                          className="space-x-2 text-right"
                                          isEdit={isInlineEditMode}
                                        >
                                          <Button
                                            variant="secondary"
                                            size="small"
                                            onClick={(e) =>
                                              onClickPropertyRemove(
                                                e,
                                                item as PropertyProps
                                              )
                                            }
                                          >
                                            Remove
                                          </Button>
                                        </Table.Td>
                                      )}
                                      {activeTab === AUTH_TABS.TASK && (
                                        <Table.Td
                                          className="space-x-2 text-right"
                                          isEdit={isInlineEditMode}
                                        >
                                          {(item as TaskProps)
                                            ?.active_status ===
                                            STATUS_ACTIVE && (
                                            <>
                                              <Button
                                                variant="secondary"
                                                size="small"
                                                onClick={(e) =>
                                                  onClickTaskEdit(
                                                    e,
                                                    item as TaskProps
                                                  )
                                                }
                                              >
                                                Edit
                                              </Button>
                                              <Button
                                                variant="secondary"
                                                size="small"
                                                onClick={(e) =>
                                                  onClickTaskRemove(
                                                    e,
                                                    item as TaskProps
                                                  )
                                                }
                                              >
                                                Delete
                                              </Button>
                                            </>
                                          )}
                                          {(item as TaskProps)
                                            ?.active_status ===
                                            STATUS_REMOVED && (
                                            <Button
                                              variant="secondary"
                                              size="small"
                                              onClick={(e) =>
                                                onClickTaskReinstate(
                                                  e,
                                                  item as TaskProps
                                                )
                                              }
                                            >
                                              Reinstate
                                            </Button>
                                          )}
                                        </Table.Td>
                                      )}
                                    </Table.Tr>
                                  );
                                }
                              )}
                          </Table.Tbody>
                        </Table>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
                {!!(total > data?.length) && (
                  <div
                    className="w-full py-2 flex justify-center items-center"
                    ref={sentryRef}
                  >
                    <Loading />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <SlidingPane
        width="360px"
        from="right"
        isOpen={isActivity}
        onRequestClose={() => setIsActivity(false)}
      >
        <MyActivity />
      </SlidingPane>
      <button
        className="fixed right-0 top-[70%] bg-jll-color-text-base-default py-2 h-[65px] w-[23px] pb-[5px] rounded-l-lg"
        onClick={() => setIsActivity(true)}
      >
        <p className="text-xs text-jll-color-text-base-reversed rotate-[270deg] mb-[-33px]">
          Activity
        </p>
      </button>
      <PropertyModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <TaskEditModal
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        selectedTask={selectedTask}
        updateData={updateData}
      />
      <TaskPreviewModal
        isOpen={isPreviewOpen}
        setIsOpen={setIsPreviewOpen}
        selectedTask={selectedTask}
        openEditModal={openEditModal}
      />
    </AuthHome>
  );
};

export default React.memo(AuthHomepage);
