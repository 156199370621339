import type { FC } from "react";
import { Avatar, Chip } from "ui-atoms";
import type { UserProps } from "types";
import { getFormatedDate, joinTwoStr, noPropagation } from "utils";

export interface StyledUserValueProps {
  user: UserProps;
  valueKey: keyof UserProps | "name" | "group";
}

const StyledUserValue: FC<StyledUserValueProps> = ({ user, valueKey }) => {
  return (
    <>
      {(() => {
        switch (valueKey) {
          case "name":
            return (
              <div className="flex space-x-2 items-center">
                <Avatar
                  alt=""
                  size="default"
                  src={user?.photo || ""}
                  name={joinTwoStr(user?.first_name, user?.last_name, " ")}
                />
                <div className="truncate">
                  <div className="font-bold text-jll-color-text-base-default text-ellipsis overflow-hidden">
                    {joinTwoStr(user?.first_name, user?.last_name, " ")}
                  </div>
                </div>
              </div>
            );
          case "email":
            return (
              <div className="text-ellipsis overflow-hidden">
                <a
                  href={`mailto:${user?.email}`}
                  className="text-jll-color-surface-info-default"
                  onClick={noPropagation}
                >
                  {user?.email}
                </a>
              </div>
            );
          case "group":
            return (
              <div className="flex space-x-2">
                {user?.is_staff && (
                  <Chip size="small" variant="secondary">
                    Staff
                  </Chip>
                )}
                {user?.is_superuser && (
                  <Chip size="small" variant="secondary">
                    Admin
                  </Chip>
                )}
                {!user?.is_superuser && !user?.is_staff && "-"}
              </div>
            );
          case "update_timestamp":
            if (!user?.update_timestamp) {
              return <>-</>;
            }
            return <>{getFormatedDate(user?.update_timestamp)}</>;
          case "create_timestamp":
            if (!user?.create_timestamp) {
              return <>-</>;
            }
            return <>{getFormatedDate(user?.create_timestamp)}</>;
          default:
            if (user.hasOwnProperty(valueKey)) {
              return <>{user[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledUserValue;
